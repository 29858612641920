import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { updateSearch } from '../api/search';
import { isEqual } from 'lodash';

const objectToQueryString = (params, arrayProperties) => {
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (arrayProperties?.includes(key) && Array.isArray(value)) {
        params[key] = value?.join(',');
      }
    });
  }

  return new URLSearchParams(params).toString();
};

const queryStringToObject = (queryString, arrayProperties) => {
  const obj = Object.fromEntries(new URLSearchParams(queryString));

  Object.entries(obj).forEach(([key, value]) => {
    if (arrayProperties?.includes(key)) {
      obj[key] = value?.split(/%2C|,/g);
    }
  });

  return obj;
};

const removeEmpties = (obj) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      obj[key] = value.filter((item) => !!item?.length);
    }

    if ([null, undefined, ''].includes(value) || (Array.isArray(value) && !value?.length)) {
      delete obj[key];
    }
  });

  return obj;
};

const arrayProperties = ['filter'];

export default function useQueryParamsSync({
  userDesks,
  userStoredFilters,
  deskId: defaultDeskId,
}) {
  // refs
  const lastDeskId = useRef(defaultDeskId);

  // States
  const [deskId, setDeskId] = useState(defaultDeskId);
  const [filters, setFilters] = useState([]);
  const [query, setQuery] = useState(undefined);
  const [favorite, setFavorite] = useState(undefined);

  const search = useMemo(() => {
    const obj = {
      query,
      deskId,
      favorite,
      filter: filters,
    };

    return removeEmpties(obj);
  }, [deskId, favorite, filters, query]);

  const storeString = useMemo(
    () => objectToQueryString(userStoredFilters, arrayProperties),
    [userStoredFilters]
  );

  // Sync URL and stored data
  useEffect(() => {
    const store = queryStringToObject(storeString);
    const changedDeskId = lastDeskId.current !== defaultDeskId;
    const searchObj = queryStringToObject(window.location.search, arrayProperties);

    const foundUserDesk = userDesks?.find((desk) => desk?._id === searchObj?.deskId);
    if (!foundUserDesk) {
      searchObj.deskId = defaultDeskId;
      setDeskId(defaultDeskId);
      lastDeskId.current = defaultDeskId;
      const state = removeEmpties(searchObj);
      window.history.replaceState(state, '', `?${objectToQueryString(state)}`);
    }

    if (!window.location?.search?.length || changedDeskId) {
      // If there is no parameters in the URL, or changed the desk, load the stored data and update URL
      store.deskId = store?.deskId ?? defaultDeskId;
      setFilters(store?.filter);
      setDeskId(store?.deskId);
      setFavorite(store?.favorite);
      setQuery(store?.query);

      const state = removeEmpties(store);

      lastDeskId.current = defaultDeskId;

      window.history.replaceState(state, '', `?${objectToQueryString(state)}`);
    } else if (!isEqual(searchObj, store)) {
      // In case of different params in the URL, update the state and the stored data

      // Change arrays to be comma-separated strings to match the expected API form
      const urlParamsCopy = { ...searchObj };
      Object.entries(urlParamsCopy).forEach(([key, value]) => {
        if (Array.isArray(value) && arrayProperties.includes(key)) {
          urlParamsCopy[key] = value.join(',');
        }
      });

      updateSearch(urlParamsCopy);
      setFilters(searchObj.filter);
      setDeskId(searchObj.deskId ?? defaultDeskId);
      setFavorite(searchObj?.favorite);
      setQuery(searchObj?.query);
    } else {
      // If the params are the same, just update the local state
      setFilters(store?.filter);
      setDeskId(store?.deskId ?? defaultDeskId);
      setFavorite(store?.favorite);
      setQuery(store?.query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDeskId, userStoredFilters]);

  // Update URL and stored data when the query change
  const updateQuery = useCallback((newQuery) => {
    setQuery(newQuery);
    const updatedParams = { ...window.history.state, query: newQuery };

    const state = removeEmpties(updatedParams);
    const queryString = objectToQueryString(state, arrayProperties);
    window.history.replaceState(state, '', `?${queryString}`);
    updateSearch(state);
  }, []);

  // Update URL and stored data when the params change
  const updateParams = useCallback((newFilters) => {
    const updatedParams = { ...window.history.state, ...newFilters };
    setFilters(updatedParams.filter);
    setFavorite(updatedParams?.favorite);
    setQuery(updatedParams?.query);

    const state = removeEmpties(updatedParams);
    const queryString = objectToQueryString(state, arrayProperties);
    window.history.replaceState(state, '', `?${queryString}`);
    updateSearch(state);
  }, []);

  return {
    search,
    // Actions
    updateQuery,
    updateParams,
  };
}
