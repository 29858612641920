import React, { useEffect, useState } from 'react';
import { styled, helpers } from 'react-free-style';
import { connect } from 'react-redux';

import { elements, icons } from '@united-talent-agency/julius-frontend-components';
import { teleTags } from '../../../../support/telemetry';

import { Icons, StatusPill, Checkbox, colors } from '@united-talent-agency/components';
import { FILTERS } from '../../../../support/cypressTags';
const { ChevronLeftIcon, StarIcon } = Icons;

const Component = ({
  search,
  navigate,
  capture,
  status: statuses,
  styles,
  filtersExpanded,
  changeFiltersExpanded,
}) => {
  const [favorite, setFavorite] = useState(search?.favorite == 1);

  useEffect(() => {
    setFavorite(search?.favorite == 1);
  }, [search?.favorite]);

  const handleFilterChange = (e) => {
    let filter = search.filter ?? [];

    const isUnchecked = filter?.indexOf(e.target.value) === -1;
    if (isUnchecked) {
      filter.push(e.target.value);
    } else if (filter) {
      filter = filter?.filter((item) => item !== e.target.value);
    }
    const updatedParams = {
      ...search,
      filter: filter?.length ? filter : undefined,
    };

    capture(teleTags.changeFilters, updatedParams);
    navigate(updatedParams);
  };

  const handleFavoriteChange = () => {
    setFavorite((oldState) => {
      const newState = !oldState;

      if (newState) {
        search.favorite = +newState;
      } else {
        search.favorite = undefined;
      }

      capture(teleTags.changeFilters, search);
      navigate(search);

      return newState;
    });
  };

  return (
    <div className={styles.container} data-cy={FILTERS.FILTERS_LIST}>
      {filtersExpanded && (
        <div className={styles.statusFilter}>
          <span>
            <span style={{ float: 'right' }}>
              <ChevronLeftIcon
                onClick={() => changeFiltersExpanded(false)}
                style={{ cursor: 'pointer', fontSize: 12, fontWeight: 700, color: '#000' }}
              />
            </span>
            <h5 style={{ fontSize: '18px', marginBottom: 20 }}>Filters</h5>
          </span>
          <div className={styles.filterGroup}>
            {statuses?.map((status) => {
              if (status.name === '' || status.status === '' || !status.status || !status.name) {
                return '';
              }

              const isChecked = search?.filter?.includes(status.status);

              return (
                <div
                  key={`filter_${status._id}_${isChecked ? 'checked' : 'unchecked'}`}
                  data-cy={FILTERS.STATUS_CHECKBOX}
                >
                  <Checkbox
                    title={status.name}
                    value={status.status}
                    checked={isChecked}
                    onChange={handleFilterChange}
                    clearedBackground
                  >
                    <div
                      data-cy={FILTERS.SIDE_PANEL_PILL}
                      style={{ marginRight: 10, marginTop: -3, width: 40 }}
                    >
                      <StatusPill status={status.status} color={status.color} />
                    </div>
                  </Checkbox>
                </div>
              );
            })}
          </div>
          <div className={styles.filterGroup}>
            <div data-cy={FILTERS.STAR_CHECKBOX}>
              <Checkbox
                title="Starred"
                value="starred"
                checked={favorite}
                onChange={handleFavoriteChange}
                clearedBackground
              >
                <div style={{ marginRight: 10, marginTop: -3, width: 40, textAlign: 'center' }}>
                  <StarIcon
                    inline
                    width={14}
                    height={14}
                    stroke={favorite ? colors.nobel : colors.darkGrey}
                    color={favorite ? colors.sunshine : colors.white}
                  />
                </div>
              </Checkbox>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const withStyles = styled({
  container: {
    marginTop: 25,
    display: 'flex',
  },
  statusFilter: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    paddingLeft: 20,
    paddingRight: 10,
  },
  filterGroup: {
    borderBottom: `1px solid rgb(204, 204, 204)`,
    paddingTop: 15,
    paddingBottom: 15,
  },
  filterButton: helpers.merge(elements.button, elements.actionable, {
    position: 'relative',
    fontSize: '0.6em',
    display: 'flex',
    width: 80,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    '&+&': {
      marginLeft: 0,
    },
  }),
  filterButtonActive: {
    borderColor: '#000',
  },
  clearButton: helpers.merge(elements.button, elements.actionable, {
    flex: 1,
    fontSize: 12,
    fontWeight: 'bold',
    border: 0,
    marginLeft: 10,
    backgroundColor: '#F5F5F5',
  }),
  starActive: icons.starActive,
  squareCrossed: icons.squareCrossed,
  incoming: icons.starOutline,
  archive: icons.archive,
  archiveButton: helpers.merge(
    {
      display: 'flex',
      padding: 10,
      marginLeft: 10,
      alignItems: 'center',
    },
    elements.actionable
  ),
  faOutline: {
    textShadow: '0px 0px 1px black',
  },
  iconSize: {
    fontSize: 14,
  },
});

const withState = connect((state) => {
  const { desk, user } = state;
  return { desk: desk.current, user, status: desk.status || [] };
});

const Filters = withStyles(withState(Component));

export default Filters;
