import React, { useEffect, useState, useRef } from 'react';
import { DatePicker } from '@united-talent-agency/components';
import { isValidDateTime, toDateTimeString } from '../../../support/date';
import { CALL_ROW } from '../../../support/cypressTags';
import EllipsisText from '../EllipsisText';

const toDate = (d) => `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;

const toTime = (d) => {
  let hours = d.getHours();
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${hours}:${d.getMinutes()}`;
};

const toAmPm = (d) => (d.getHours() >= 12 ? 'pm' : 'am');

/**
 * Inline date picker
 * @param {number} occurrenceDate: A number representing the milliseconds elapsed between
 *    1 January 1970 00:00:00 UTC and the given value.
 * @param {function} onSave: hook for saving an updated Date
 * @param {Object} styles: provided styling classes
 * @returns {JSX.Element}
 * @constructor
 */
const CallDate = ({ occurrenceDate, onSave, styles }) => {
  const initialDateTime = new Date(occurrenceDate);

  // Refs
  const cellRef = useRef();

  // States
  const [dateTime, setDateTime] = useState(initialDateTime);
  const [date, setDate] = useState(toDate(initialDateTime));
  const [time, setTime] = useState(toTime(initialDateTime));
  const [twelveHour, setTwelveHour] = useState(toAmPm(initialDateTime));
  const [editing, setEditing] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    const newDateTime = new Date(`${date}, ${time} ${twelveHour}`);
    if (!editing && newDateTime.getTime() !== occurrenceDate) {
      if (updated) {
        setUpdated(false);
        setDateTime(newDateTime);
        onSave({ occurrence_date: newDateTime.getTime() });
      } else {
        const newDateTime = new Date(occurrenceDate);
        setDateTime(newDateTime);
        setDate(toDate(newDateTime));
        setTime(toTime(newDateTime));
        setTwelveHour(toAmPm(newDateTime));
      }
    }
  }, [updated, date, time, twelveHour, occurrenceDate, onSave, editing]);

  const onBlur = () => {
    setUpdated(true);
    setEditing(false);
  };

  useEffect(() => {
    if (!editing || !cellRef.current) {
      return;
    }

    let blurListener;

    setTimeout(() => {
      blurListener = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setEditing(false);
        }
      };

      cellRef.current.querySelector('button').click();
      cellRef.current.querySelector('& > div').addEventListener('blur', blurListener);
    }, 0);

    return () => {
      if (blurListener && cellRef.current) {
        cellRef.current?.querySelector('button').removeEventListener('blur', blurListener);
      }
    };
  }, [editing]);

  return (
    <>
      {editing ? (
        <td ref={cellRef} data-cy={CALL_ROW.DATE}>
          <DatePicker
            timeIncluded
            inputFieldReadOnly
            focusPicker
            onChangeTime={setTime}
            onChangeTwelveHourPeriod={setTwelveHour}
            timeString={time}
            twelveHourPeriod={twelveHour}
            dateString={date}
            onChange={setDate}
            onBlur={onBlur}
            dateButtonCypressTag={CALL_ROW.DATE_DAY_BUTTON}
            dateLabelCypressTag={CALL_ROW.DATE_INNER_LABEL}
          />
        </td>
      ) : (
        <td
          data-cy={CALL_ROW.DATE}
          className={styles.date}
          onClick={() => {
            setEditing(true);
          }}
        >
          <div>
            <time dateTime={isValidDateTime(dateTime) ? dateTime.toISOString() : ''}>
              <EllipsisText text={toDateTimeString(dateTime)} />
            </time>
          </div>
        </td>
      )}
    </>
  );
};

export default CallDate;
