import React from 'react';
import Modal from 'react-modal';
import { Icons, Button, colors } from '@united-talent-agency/components';
import {
  ButtonContainer,
  ModalTitle,
  ConfirmationText,
  BoldSpan,
  SaveButtonContainer,
} from './styles';
import { CALL_LIST } from '../../../support/cypressTags';

const modalStyles = {
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    border: '0.5px solid rgba(0, 0, 0, 0.2)',
    background: `${colors.background} none repeat scroll 0% 0%`,
    borderRadius: 2,
    outline: 'currentcolor none medium',
    width: 454,
    overflow: 'unset',
    height: 'min-content',
    padding: '20px 0',
  },
};

const DeleteModal = ({ isOpen, onCancel, onConfirm, impactedCount }) => (
  <Modal isOpen={isOpen} onRequestClose={onCancel} style={modalStyles}>
    <div>
      <ButtonContainer>
        <Icons.XCloseIcon
          inline
          onClick={onCancel}
          height={23}
          width={23}
          style={{ cursor: 'pointer', marginRight: -7 }}
        />
      </ButtonContainer>

      <ModalTitle>Delete Confirmation</ModalTitle>

      <ConfirmationText>
        Delete <BoldSpan>{impactedCount}</BoldSpan> record{impactedCount > 1 ? 's' : ''}?:
      </ConfirmationText>

      <SaveButtonContainer data-cy={CALL_LIST.BULK_DELETE_CONFIRMATION}>
        <Button small onClick={() => onConfirm && onConfirm()} text="Confirm" />
      </SaveButtonContainer>
    </div>
  </Modal>
);

export default DeleteModal;
