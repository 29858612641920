import React from 'react';

const FormMessage = ({ children, ...props }) => (
  <p
    style={{
      color: 'red',
      fontSize: '12px',
      textAlign: 'center',
      marginBottom: 0,
    }}
    {...props}
  >
    {children}
  </p>
);

export default FormMessage;
