import { get } from '.';

//get bucket sizes defined by .env
const {
  REACT_APP_PERSON_SEARCH_MAX_RESULTS_CLIENT,
  REACT_APP_PERSON_SEARCH_MAX_RESULTS_EMPLOYEE,
  REACT_APP_PERSON_SEARCH_MAX_RESULTS_INDUSTRY_CONTACT,
  REACT_APP_PERSON_SEARCH_MAX_RESULTS_SHARED_CONTACT,
} = process.env;

//transforms a string to an integer - returns 0 if string is not a number
const parseIntNaNIsZero = (value) => {
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    return 0;
  }
  return parsed;
};

//parse bucket sizes
const maxResultsClient = parseIntNaNIsZero(REACT_APP_PERSON_SEARCH_MAX_RESULTS_CLIENT);
const maxResultsEmployee = parseIntNaNIsZero(REACT_APP_PERSON_SEARCH_MAX_RESULTS_EMPLOYEE);
const maxResultsIndustryContact = parseIntNaNIsZero(
  REACT_APP_PERSON_SEARCH_MAX_RESULTS_INDUSTRY_CONTACT
);
const maxResultsSharedContact = parseIntNaNIsZero(
  REACT_APP_PERSON_SEARCH_MAX_RESULTS_SHARED_CONTACT
);

//format static buckets for consumption
const personTypeBuckets = {
  client: { type: 'Client', maxResults: maxResultsClient },
  employee: { type: 'Employee', maxResults: maxResultsEmployee },
  industryContact: { type: 'Industry Contact', maxResults: maxResultsIndustryContact },
  sharedContact: { type: 'Shared Contact', maxResults: maxResultsSharedContact },
};

//various modes (mechanisms) for hitting the /people api
export const searchModes = {
  startsWith: { field: 'name[predicate]', addQuotes: false },
  containsExactPhrase: { field: 'search', addQuotes: true },
};

/**
 * Makes an API call to the GET /people resource
 *    getPeople("asdf", ['Client', Industry Contact']) =>
 *      /people?search="{searchText}"&type={types[0]}&type={types[1]} =>
 *      /people?search="asdf"&type=Client&type=Industry%20Contact
 *
 * @param searchText: The text to search for in people - exact search is automatically applied here by surrounding the search text with double-quotes
 * @param searchMode: One of the search modes defined by searchModes (defaults to startsWith)
 * @param types: The types of people to search for: Client, Employee, Industry Contact, Shared Contact
 *               Searches all of the aforementioned types if not specified
 * @param limit: The maximum number of results (default 10)
 * @param sort: The field to order sorting by (default name)
 * @param show: An array of complex fields to pull back in addition to the standard simple fields
 * @returns A list of people from the response payload (no meta result yet)
 **/
export const getPeople = (
  searchText,
  {
    searchMode = searchModes.startsWith,
    types = [
      personTypeBuckets.client.type,
      personTypeBuckets.employee.type,
      personTypeBuckets.industryContact.type,
      personTypeBuckets.sharedContact.type,
    ],
    limit = 10,
    show = ['contacts'],
    sort = 'name',
  } = {},
  _get = get
) => {
  const query = [{ sort }];
  if (searchText) {
    const searchVal = searchMode.addQuotes ? `"${searchText}"` : searchText;
    //wrap search text in double quotes to force an exact match search
    query.push({ [searchMode.field]: searchVal });
  }
  types.forEach((t) => {
    query.push({ type: t });
  });
  if (limit) {
    query.push({ limit });
  }
  if (show) {
    show.forEach((field) => {
      query.push({ show: field });
    });
  }

  const execPlan = _get(`/people`, query).then((response) => {
    const { errors, data, meta } = (response || {}).body || {};
    if (errors) {
      //errors is an array, but v3 only returns a single error in the array
      //this is for api-handled errors,
      //  - unhandled errors shouldnt get this far (such as network outage)
      return Promise.reject(errors[0]);
    }
    const results = { data, meta };
    return results;
  });
  return execPlan;
};

export const getPrivateContacts = (id) => get(`/v2/people/${id}/private-contacts`);

export const getNotes = (id, deskId) => {
  const query = { deskId };
  return get(`/v2/people/${id}/notes`, query);
};

/**
 * Performs many small individual searches for each desired person type.
 * - Priority is given to "startsWith" search results.
 * - If startsWith search results don't fill the bucket,
 *     the remainder of the bucket is filled with "containsExactPhrase" search results.
 * @param searchText: The text to search for
 *
 **/
export const getPeopleByTypeBuckets = (
  searchText,
  { _personTypeBuckets = personTypeBuckets, _getPeople = getPeople } = {}
) => {
  //create a bunch of startsWith searches for the provided searchText
  const startsWithSearches = Object.values(_personTypeBuckets).map((personType) => {
    if (personType.maxResults > 0) {
      return _getPeople(searchText, {
        searchMode: searchModes.startsWith,
        types: [personType.type],
        limit: personType.maxResults,
      });
    }
    return Promise.resolve([]);
  });
  //create a bunch of containsExactPhrase searches for the provided searchText
  const containsExactSearches = Object.values(_personTypeBuckets).map((personType) => {
    if (personType.maxResults > 0) {
      return _getPeople(searchText, {
        searchMode: searchModes.containsExactPhrase,
        types: [personType.type],
        limit: personType.maxResults * 2, //double - worst case scenario: first x results are the same as the startsWith search
      });
    }
    return Promise.resolve([]);
  });

  //execute all the searches (8, total, at this time)
  const allSearches = startsWithSearches.concat(containsExactSearches);

  return Promise.all(allSearches).then((results) => {
    const retVal = [];
    //iterate over each personType
    Object.values(_personTypeBuckets).forEach((personType) => {
      //get both the search results (startsWith and containsExactPhrase) for a given personType.type
      const getResultsForPersonType = (result) =>
        (result?.data || []).some((p) => p.type === personType.type);

      /**
       *  flatten the 2 searches down to a single array, prioritizing startsWith (the first result)
       *  - fill any space remaining in the array with the first results from the containsExactPhrase search
       **/
      const compactResultsByPersonType = (agg, result) => {
        if (agg.length < personType.maxResults) {
          const unusedPeople = (result?.data || []).filter(
            (p) => !agg.some((p2) => p2._id === p._id)
          );
          agg = agg.concat(unusedPeople.slice(0, personType.maxResults - agg.length));
        }
        return agg;
      };

      const data = results.filter(getResultsForPersonType).reduce(compactResultsByPersonType, []);

      // flatten again into a single array with other person types
      retVal.push(...data);
    }); //end - iterate over each personType

    //package the data to be consistent with calling getPeople directly
    const packaged = { data: retVal };
    return packaged;
  });
};
