import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { colors } from '@united-talent-agency/components';
import { deleteCommunication } from '../../api/communications';
import { SortableColumnHeader, Checkbox } from '@united-talent-agency/components';
import LoadMore from '../../components/LoadMore';
import { saveCall } from '../../data/call-todo';
import { CALL_LIST } from '../../support/cypressTags';
import { searchDeskContacts } from '@united-talent-agency/julius-frontend-store';
import DeleteCommunication from '../../components/DeleteCommunication';
import DeleteCallModal from '../../components/DeleteCallModal';
import BulkUpdateStatus from '../../components/BulkUpdateStatus';
import CallRow from '../../components/CallRow';
import { useDebounce } from 'use-debounce';

const NUMBER_OF_COLUMN = 9;

const Component = ({
  styles,
  communications,
  onEditDone,
  search,
  selectAll,
  selectExcept,
  setSelectAll,
  setSelectExcept,
  desk,
  statuses,
  dispatch,
  editCallTodo,
  fetchCounts,
  totalCounts,
  onLoadMore,
  statusOfLoadingMore,
}) => {
  // States
  const [showDialog, setShowDialog] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [allDeskContacts, setAllDeskContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [debouncedLoading] = useDebounce(loading, 500);

  const { companyName: showCompanyName = false } = useFlags();

  const loadDeskContacts = useCallback(async () => {
    if (!desk?._id) {
      return;
    }

    setLoading(true);

    dispatch(searchDeskContacts(desk._id, ''))
      .then((res) => {
        if (res?.body?.error) {
          console.error(res.body.error);
          setAllDeskContacts([]);
        }
        setAllDeskContacts(res.body ?? []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [desk, dispatch]);

  useEffect(() => {
    loadDeskContacts();
  }, [loadDeskContacts]);

  /**
   * Add the contacts to the calls
   */
  const communicationsWithContacts = useMemo(
    () =>
      communications.map((call) => ({
        ...call,
        contacts:
          allDeskContacts?.find((person) => person.name === call.recipientName)?.contacts ?? [],
      })),
    [allDeskContacts, communications]
  );

  /**
   * Group calls by status
   */
  const callsByStatus = useMemo(
    () =>
      communicationsWithContacts.reduce((agg, communication) => {
        if (!agg[communication.status]) {
          agg[communication.status] = [];
        }
        agg[communication.status].push(communication);
        return agg;
      }, {}),
    [communicationsWithContacts]
  );

  /**
   * Sorts the list of calls by existing statuses in "statuses" and places the unfound statuses in "statuses" at the end of the list
   */
  const list = useMemo(
    () =>
      Object.entries(callsByStatus || {}).sort((a, b) => {
        const i1 = statuses.findIndex((status) => status.status === a[0]);
        const i2 = statuses.findIndex((status) => status.status === b[0]);

        return (i1 === -1 ? statuses.length : i1) - (i2 === -1 ? statuses.length : i2);
      }),
    [callsByStatus, statuses]
  );

  const hideBulkEdits = desk?.settings?.hideBulkEdits;

  const onSelectedChange = useCallback(
    (call) => () => {
      if (selectExcept?.has(call._id)) {
        selectExcept.delete(call._id);
      } else if (selectExcept) {
        selectExcept.add(call._id);
      }
      setSelectExcept(new Set(selectExcept));
    },
    [selectExcept, setSelectExcept]
  );

  if (!statuses) {
    return <div />;
  }

  return (
    <div className={styles.container}>
      {!hideBulkEdits && (
        <div className={styles.bulkActionContainer}>
          <BulkUpdateStatus
            selectAll={selectAll}
            selectExcept={selectExcept}
            statuses={statuses}
            filterParams={search}
            onUpdateApplied={() => {
              setSelectAll(false);
              setSelectExcept(new Set());
              onEditDone && onEditDone();
            }}
          />
          <span className={styles.pipe} />
          <DeleteCommunication
            selectAll={selectAll}
            selectExcept={selectExcept}
            filterParams={search}
            onUpdateApplied={() => {
              setSelectAll(false);
              setSelectExcept(new Set());
              onEditDone && onEditDone();
            }}
          />
        </div>
      )}
      <table
        id="callTable"
        data-loading={debouncedLoading}
        className={styles.table}
        data-cy={CALL_LIST.CALL_LIST}
        data-hide-company={!showCompanyName}
        data-hide-bulk-edit={hideBulkEdits}
      >
        <thead>
          <tr className={styles.tr}>
            {!hideBulkEdits && (
              <th name="checkbox" className={styles.checkboxColumn}>
                <Checkbox
                  checked={selectAll}
                  indeterminate={selectExcept?.size}
                  clearedBackground
                  onChange={(e) => {
                    const { checked } = e.currentTarget;
                    setSelectAll(checked);
                    setSelectExcept(new Set());
                  }}
                />
              </th>
            )}
            <th name="status" style={{ textAlign: 'center' }} className={styles.statusColumn}>
              <SortableColumnHeader text="Status" lightWeight />
            </th>
            <th name="starred" className={styles.starredColumn} />
            <th name="name" className={styles.nameColumn}>
              <SortableColumnHeader text="Name" lightWeight />
            </th>
            <th name="company" className={styles.companyColumn}>
              <SortableColumnHeader text="Company" lightWeight />
            </th>
            <th name="contact" style={{ paddingLeft: 7 }} className={styles.contactColumn}>
              <SortableColumnHeader text="Contact" lightWeight />
            </th>
            <th name="date" className={styles.dateColumn}>
              <SortableColumnHeader text="Date" lightWeight />
            </th>
            <th name="notes" className={styles.notesColumn} style={{ paddingLeft: 8 }}>
              <SortableColumnHeader text="Notes" lightWeight />
            </th>
            <th
              name="actions"
              style={{ textAlign: 'end', paddingLeft: 12 }}
              className={styles.actionsColumn}
            >
              <SortableColumnHeader text="Actions" lightWeight />
            </th>
          </tr>
        </thead>
        {list?.map(([status, calls]) => (
          <tbody key={status} data-status={status} data-cy={CALL_LIST.STATUS_SECTION}>
            {calls?.map((call) => (
              <CallRow
                key={`${status}-${call._id}`}
                hideBulkEdits={desk?.settings?.hideBulkEdits}
                dispatch={dispatch}
                item={call}
                recipient={call?.recipientId}
                editCallTodo={editCallTodo}
                onDeleteItem={(item) => {
                  setShowDialog(true);
                  setToDelete(item);
                }}
                statuses={statuses}
                onSave={(callTodo) =>
                  saveCall(callTodo).then((resp) => {
                    onEditDone && onEditDone();
                    return resp.data;
                  })
                }
                selected={selectAll ? !selectExcept?.has(call._id) : selectExcept?.has(call._id)}
                onSelectedChange={onSelectedChange(call)}
                deskContacts={call?.contacts}
              />
            ))}
            {fetchCounts[status] < totalCounts[status] && (
              <tr key={`loadMore:${status}`} data-cy={CALL_LIST.LOAD_MORE}>
                <td colSpan={NUMBER_OF_COLUMN}>
                  <LoadMore
                    loading={statusOfLoadingMore === status}
                    onClick={() => onLoadMore(status)}
                  />
                </td>
              </tr>
            )}
            <tr data-cy={CALL_LIST.STATUS_DIVIDER} key={`statusDivider:${status}`}>
              <td className={styles.divider} colSpan={NUMBER_OF_COLUMN} />
            </tr>
          </tbody>
        ))}
      </table>
      <DeleteCallModal
        isOpen={showDialog}
        onConfirm={() => {
          deleteCommunication(toDelete?._id).then(() => {
            onEditDone && onEditDone();
          });
          setToDelete(null);
          setShowDialog(false);
        }}
        onCancel={() => {
          setShowDialog(false);
        }}
      />
    </div>
  );
};

const withStyles = styled({
  container: {
    containerName: 'call-list-container',
    containerType: 'inline-size',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 10,
    tableLayout: 'fixed',
    ['&[data-hide-company="true"]']: {
      ['[name="checkbox"]']: {
        width: '6%',
      },
      ['[name="status"]']: {
        width: '8%',
      },
      ['[name="starred"]']: {
        width: '7%',
      },
      ['[name="name"]']: {
        width: '12%',
      },
      ['[name="company"]']: {
        display: 'none',
      },
      ['[name="contact"]']: {
        width: '17%',
      },
      ['[name="date"]']: {
        width: '14%',
      },
      ['[name="notes"]']: {
        width: '28%',
      },
      ['[name="actions"]']: {
        width: '8%',
      },
      ['&[data-hide-bulk-edit="true"]']: {
        ['[name="actions"]']: {
          width: '14%',
        },
      },
    },
    ['&[data-hide-company="false"][data-hide-bulk-edit="true"] [name="actions"]']: {
      width: '8%',
    },
  },
  tr: {
    '> th': {
      color: colors.darkGrey,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 2.5,
      paddingRight: 2.5,
      paddingBottom: 3,
    },
  },
  bulkActionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 15,
    marginLeft: 15,
  },
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
  },
  pipe: {
    display: 'inline-block',
    width: 1,
    height: 20,
    backgroundColor: colors.disabledGrey,
    margin: '0 10px',
  },
  bulkStatusUpdateItem: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    '&:hover': { backgroundColor: 'rgba(41, 161, 219, 0.15)' },
  },

  // #region Columns style
  checkboxColumn: {
    width: '3%',
    ['@container call-list-container (max-width: 1150px)']: {
      width: '6%',
    },
  },
  statusColumn: {
    width: '5%',
    ['@container call-list-container (max-width: 1150px)']: {
      width: '8%',
    },
  },
  starredColumn: {
    width: '2%',
    ['@container call-list-container (max-width: 1150px)']: {
      width: '7%',
    },
  },
  nameColumn: {
    width: '11%',
    ['@container call-list-container (max-width: 1150px)']: {
      width: '12%',
    },
  },
  companyColumn: {
    width: '25%',
    ['@container call-list-container (max-width: 1150px)']: {
      display: 'none',
    },
  },
  contactColumn: {
    width: '14%',
    ['@container call-list-container (max-width: 1150px)']: {
      width: '17%',
    },
  },
  dateColumn: {
    width: '10%',
    ['@container call-list-container (max-width: 1150px)']: {
      width: '14%',
    },
  },
  notesColumn: {
    width: '25%',
    ['@container call-list-container (max-width: 1150px)']: {
      width: '28%',
    },
  },
  actionsColumn: {
    width: '8%',
  },
  // #endregion Columns style
});

const withState = connect(({ desk = {} }) => ({
  statuses: desk.status,
  desk: desk.current,
}));

const CallList = withStyles(withState(Component));

export default CallList;
