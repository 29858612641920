import React from 'react';
import Modal from 'react-modal';
import { styled, helpers } from 'react-free-style';
import classnames from 'classnames';
import * as elements from '../../styles/elements';
import { EXISTING_CONTACT_MODAL } from '../../support/cypressTags';

const DuplicateCallModal = ({ isOpen, onCancel, onCreateNew, onUseExisting, styles }) => (
  <Modal isOpen={isOpen} onRequestClose={onCancel} className={classnames(styles.content)}>
    <div data-cy={EXISTING_CONTACT_MODAL.MODAL} className={styles.container}>
      <h1>A call record exists for this person. Do you want to create another?</h1>
      <div className={styles.buttonsContainer}>
        {/* This button order is mandatory to start focusing the "create new" button first, but keep it as the second button */}
        <button
          data-cy={EXISTING_CONTACT_MODAL.BUTTON_CREATE_NEW}
          className={classnames(styles.button, styles.secondButton)}
          onClick={() => {
            onCreateNew && onCreateNew();
          }}
        >
          Create New
        </button>
        <button
          data-cy={EXISTING_CONTACT_MODAL.BUTTON_USE_EXISTING}
          className={classnames(styles.button, styles.firstButton)}
          onClick={() => {
            onUseExisting && onUseExisting();
          }}
        >
          Use Existing
        </button>
      </div>
    </div>
  </Modal>
);

const withStyles = styled({
  content: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-50px',
    width: '450px',
    backgroundColor: '#333',
    overflow: 'hidden',
    boxShadow: '20px 20px 20px #bbb',
    border: '1px solid #141414',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '450px',
    background: 'white',
    opacity: 1,
    padding: '40px',
    boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
    color: '#4a4a4a',
    border: '1px solid #4a4a4a',
  },
  button: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    margin: '5px',
    '&:focus': {
      borderColor: 'rgb(33, 135, 185)',
      outline: '1px solid rgb(33, 135, 185)',
    },
  }),
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  firstButton: {
    order: 1,
  },
  secondButton: {
    order: 2,
  },
});

export default withStyles(DuplicateCallModal);
