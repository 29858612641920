import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import CallList from '../../CallList';
import {
  createShortcuts,
  filterInputEvent,
  keyboard,
  stringifyKey,
} from '../../../support/keyboard';

/**
 * @typedef {object} Search
 * @property {string} desk: current desk mongo document id
 * @property {string} [favorite]: 1 or 0 or undefined
 * @property {string} [filter]: comma separated string of status abbreviations
 * @property {string} [query]: current user-search entered into the search bar
 **/
const Component = ({
  search,
  totalCounts,
  fetchCounts,
  callTodos,
  editCallTodo,
  selectAll,
  setSelectAll,
  selectExcept,
  communications,
  setSelectExcept,
  statusOfLoadingMore,
  loadMore,
  fullRefresh,
}) => {
  // States
  const [focused, setFocused] = useState(0);

  const move = useCallback(
    (e, pos) => {
      e.preventDefault();

      setFocused(Math.min(Math.max(0, focused + pos), (callTodos?.length || 0) - 1));
    },
    [callTodos?.length, focused]
  );

  useEffect(() => {
    const keyboardListener = filterInputEvent(
      createShortcuts({
        [stringifyKey('up')]: (e) => move(e, -1),
        [stringifyKey('j')]: (e) => move(e, -1),
        [stringifyKey('down')]: (e) => move(e, 1),
        [stringifyKey('h')]: (e) => move(e, 1),
        [stringifyKey('v')]: () => {
          /** keep */
        },
      })
    );

    keyboard.addListener(keyboardListener);

    return () => {
      keyboard.removeListener(keyboardListener);
    };
  }, [move]);

  return (
    <div>
      <CallList
        communications={communications}
        totalCounts={totalCounts}
        fetchCounts={fetchCounts}
        editCallTodo={editCallTodo}
        search={search}
        statusOfLoadingMore={statusOfLoadingMore}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        selectExcept={selectExcept}
        setSelectExcept={setSelectExcept}
        onLoadMore={(status) => loadMore(status)}
        onEditDone={fullRefresh}
      />
    </div>
  );
};

const withState = connect((store) => {
  const { desk = {} } = store;
  const { calls, preview } = store.callTodo ? store.callTodo : {};
  const { editCallTodo } = store.callForm ? store.callForm : {};
  const { callTodos } = calls || {};

  const hideFutureCalls =
    store.user && desk.current && desk.current.settings && desk.current.settings.hideFutureCalls;

  return {
    callTodos,
    hideFutureCalls,
    preview,
    editCallTodo,
    statusArray: desk.status,
  };
});

const CallListWrapper = withState(Component);

export default CallListWrapper;
